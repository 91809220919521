<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员学习记录</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">个人学习记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="课件名称" class="searchboxItem">
              <span class="itemLabel">课件名称:</span>
              <el-input
                v-model="kpointName"
                type="text"
                size="small"
                placeholder="请输入课件名称"
                clearable
              />
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="学员姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="课程名称"
                align="center"
                show-overflow-tooltip
                prop="courseName"
              />
              <el-table-column
                label="课件名称"
                align="center"
                show-overflow-tooltip
                prop="kpointName"
              />
              <el-table-column
                label="视频时长"
                align="center"
                show-overflow-tooltip
                prop="totalTimeSum"
              >
                <template slot-scope="scope">
                  <span>{{ getTime(scope.row.totalTimeSum) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="已观看时长"
                align="center"
                show-overflow-tooltip
                prop="totalWatchTimeSum"
              >
                <template slot-scope="scope">
                  <span>{{ getTime(scope.row.totalWatchTimeSum) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="400px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="
                      lookauthenphoto(scope.row.kpointId, scope.row.projectId)
                    "
                    >查看认证记录</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="showHeartbeatWin(scope.row.studyId)"
                    >查看心跳记录</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="seeVideoInfo(scope.row)"
                    >查看视频记录</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeRollCall(scope.row)"
                    >查看点名记录</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisiblePhoto"
      width="60%"
      :before-close="handleClose"
    >
      <section>
        <div class="imgBox">
          <div class="CertificationBox">
            <div class="certificationTitle">
              <span>认证模板</span>
            </div>
            <div class="certifictionImg">
              <el-image
                :src="facepicture.userFace"
                v-if="facepicture.userFace"
              ></el-image>
              <span v-else>未认证</span>
            </div>
          </div>
          <div class="CaptureBox">
            <div class="CaptureTitle">
              <span>学习抓拍照片</span>
            </div>
            <div class="CaptureList" v-if="facepicture.list">
              <div
                v-for="(item, index) in facepicture.list"
                :key="index"
                class="certifictionImg"
              >
                <el-image :src="item.approveFace"></el-image>
                <p>认证时间: {{ item.createTime }}</p>
                <p>认证结果: {{ item.faceCause }}</p>
              </div>
            </div>
            <div v-else class="CaptureList">
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>
    </el-dialog>
    <el-dialog :visible.sync="heartbeatVisible" width="60%">
      <el-table
        :data="heartbeatData"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        stripe
        :header-cell-style="tableHeader"
      >
        <el-table-column
          label="视频播放时间(秒)"
          align="center"
          show-overflow-tooltip
          prop="oldTime"
        />
        <el-table-column
          label="记录时间"
          align="center"
          show-overflow-tooltip
          prop="createTime"
        />
      </el-table>
      <el-pagination
        :total="heartbeatTotal"
        background
        :page-size="heartbeatQuery.pageSize"
        :current-page="heartbeatQuery.pageNum"
        layout="total, prev, pager, next, jumper"
        @current-change="heartbeatLoad"
      />
    </el-dialog>
    <el-dialog
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="closedialogVisible1"
    >
      <video
        :src="videoUrl"
        autoplay
        controls
        width="100%"
        height="500px"
      ></video>
      <!-- <player_2 :videoId="videoId" :videoTime="videoTime"  ref="mychild"/> -->
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
// import player_1 from "@/components/player";
// import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
export default {
  name: "ViewLearningRecords",
  components: {
    Empty,
    PageNum,
    // player_1,
    // player_2
  },
  mixins: [List],
  data() {
    return {
      kpointName: "",
      courseName: "",
      projectName: "",
      studyProgress: "",
      dialogVisiblePhoto: false,
      dialogCert: false,
      facepicture: "",
      heartbeatVisible: false,
      heartbeatData: [],
      heartbeatTotal: 0,
      heartbeatQuery: {
        pageNum: 1,
        pageSize: 10,
        studyId: 0,
      },
      dialogVisible1: false,
      videoUrl: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.$route.query.userId) {
        params.userId = this.$route.query.userId;
      }
      if (this.$route.query.projectId) {
        params.projectId = this.$route.query.projectId;
      }
      this.doFetch({
        url: "/gov/project/queryPersonalStudyRecordList",
        params,
        pageNum,
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    /* 查看学习记录 */
    lookauthenphoto(kpointId, projectId) {
      this.dialogVisiblePhoto = true;
      this.$post("/gov/project/queryPersonalStudyRecordFace", {
        kpointId: kpointId,
        projectId: projectId,
        userId: this.$route.query.userId,
      })
        .then((res) => {
          this.facepicture = res.data;
          if (this.facepicture.list.faceState == true) {
            return (this.faceState = "成功");
          } else {
            return (this.faceState = "失败");
          }
        })
        .catch(() => {
          return;
        });
    },
    // // 预览
    // showVideo(row) {
    //   this.dialogVisible1 = true;
    //   this.videoUrl = row.videoUrl;
    //   this.videotitle = row.userName + "(" + row.kpointName + ")";
    // },
    closedialogVisible1() {
      this.dialogVisible1 = false;
      this.videoUrl = "";
      this.videotitle = "";
    },
     // 查看视频记录
    seeVideoInfo(item){
        sessionStorage.setItem("row", JSON.stringify(item));
      sessionStorage.setItem("projectIdYw", this.projectId);
      this.$router.push({
        path: "/web/classListCom/classDetailsFile/videoInfo",
        query: { studyId: item.studyId, platformType: 3},
      });
    },
    // 查看点名记录
    seeRollCall(item){
       sessionStorage.setItem("row", JSON.stringify(item));
      sessionStorage.setItem("projectIdYw", this.projectId);
      this.$router.push({
        path: "/web/classListCom/classDetailsFile/rollCall",
        query: { studyId: item.studyId, platformType: 3 },
      });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    showHeartbeatWin(studyId) {
      this.heartbeatData = [];
      this.heartbeatVisible = true;
      this.heartbeatQuery.studyId = studyId;
      this.heartbeatQuery.pageNum = 1;
      this.heartbeatLoad();
    },
    heartbeatLoad(pageNum = 1) {
      if (this.heartbeatQuery.studyId) {
        this.heartbeatQuery.pageNum = pageNum;
        this.$post("/gov/project/v1/heartbeat/query", this.heartbeatQuery)
          .then((ret) => {
            this.heartbeatTotal = ret.data.total;
            this.heartbeatData = ret.data.list;
          })
          .catch(() => {
            return;
          });
      }
    },
  },
};
</script>
<style lang="less">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5c6be8;
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.imgBox {
  display: flex;
  border: 1px solid #797979;
  .CertificationBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    border-right: 1px solid #797979;
    .certificationTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 210px;
        height: 230px;
      }
    }
  }
  .CaptureBox {
    flex: 1;
    width: 500px;
    .CaptureTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .CaptureList {
      display: flex;
      overflow-y: auto;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 210px;
        height: 230px;
      }
      p {
        padding: 5px 0;
      }
    }
  }
}
</style>

